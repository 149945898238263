/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useMemo, useState } from "react"
import { Button, Spinner } from "react-bootstrap-v5"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { ISuggestedUniversityResponseDto, ISuggestionOptionsDto, ISuggestUniversityRequestDto, IUniversitySuggestionMetaResponseDto, UniversitySuggestionState } from "./models/UniversitySuggestionDtos"
import * as api from './service/UniversitySuggestion.service';
import { getStudentAcademicProfile, getStudentProfile } from "../student/services/StudentProfileService"
import { IAcademicProfile, StudentProfile } from "../student/models"
import { ProfileHeader } from "../student-profiles/components/ProfileHeader"
import { PageTitle } from "../../../theme/layout/core"
import { SuggestUniversitiesForm } from "./components/SuggestUniversitiesForm"
import { AddSuggestionPanel } from "./components/AddSuggestionPanel";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Switch } from "@material-ui/core";
import { useSharedState } from "../../modules/common/SharedStateProvider";
import { AdminComments } from "./components/SuggestionComments";
import { GraduationDetailsOverview } from "../student-profiles/components/GraduationDetailsOverview";
import { ExamScoresOverview } from "../student-profiles/components/ExamScoresOverview";
import MaterialTable from "material-table";
import { WorkDetailsOverview } from "../student-profiles/components/WorkDetailsOverview";

type Props = {
    profileId: string
}

const SuggestedUniversityPage: FC<RouteComponentProps<Props>> = ({ history, match: { params: { profileId } } }) => {
    const defaultMeta: IUniversitySuggestionMetaResponseDto = {
        suggestions: [],
        archivedSuggestions: [],
        profileId: +profileId,
        id: 0,
        state: UniversitySuggestionState.Suggestion
    }

    const sharedState = useSharedState();

    const [profile, setProfile] = useState<StudentProfile>();
    const [academicProfile, setAcademicProfile] = useState<IAcademicProfile>();
    const [options, setOptions] = useState<ISuggestionOptionsDto>();
    const [suggestionPanelVisible, setSuggestionPanelVisible] = useState<boolean>();
    const [refreshKey, setRefreshKey] = useState<number>(0);
    const [approveAndSendToStudent, setApproveAndSendToStudent] = useState<boolean>();
    const [adminComment, setAdminComment] = useState<string>();
    const [loading, setLoading] = useState<boolean>();

    const formik = useFormik({
        onSubmit: (values) => {
            handleSuggest(values);
        },
        initialValues: defaultMeta
    });

    useEffect(() => {
        setApproveAndSendToStudent(sharedState.isSuperAdmin);
    }, [sharedState.isSuperAdmin]);


    useEffect(() => {
        const fetchData = () => {
            api.getSuggestionsMeta(+profileId)
                .then(({ data }) => {
                    data = {
                        ...data,
                        suggestions: (data?.suggestions || []),
                        archivedSuggestions: (data?.archivedSuggestions || []).map(x => {
                            return { ...x, universityName: x.university?.universityName, reason: JSON.stringify(x.comments) }
                        })
                    };
                    formik.setValues(data);
                    return data;
                });
            getStudentProfile(+profileId)
                .then(({ data }) => setProfile(data));

            getStudentAcademicProfile(+profileId)
                .then(({ data }) => setAcademicProfile(data));

            api.getSuggestionOptions(+profileId)
                .then(({ data }) => setOptions(data));
        }

        if (profileId) {
            fetchData();
        }
        //eslint-disable-next-line
    }, [profileId, refreshKey]);

    const handleSaveSuggestion = (sugg: ISuggestedUniversityResponseDto) => {
        const suggestions = [...formik.values.suggestions, sugg];
        formik.setFieldValue('suggestions', suggestions);
        setSuggestionPanelVisible(!suggestionPanelVisible);
    }

    const suggestedIds = useMemo(() => formik.values.suggestions.map(x => x.universityId), [formik.values.suggestions])

    const handleSuggest = (values: IUniversitySuggestionMetaResponseDto) => {
        // const isInvalid = values.suggestions.some(x => x.status === SuggestedUniversityStatus.RequestedChanges && !x.comment)
        // if (isInvalid) {
        //     formik.setStatus('You must either comment on suggestions student requesting changes or delete them.');
        //     window.scrollTo({ top: 0 });
        //     return;
        // }

        formik.setStatus(undefined);

        setLoading(true);
        const suggestions: Array<ISuggestUniversityRequestDto> = values.suggestions
            .map(x => ({ universityId: x.universityId, comment: x.comment, isDirect: x.isDirect, partnerId: x.partnerId, suggestionLevel: x.suggestionLevel }));
        const request = { profileId: +profileId, suggestions, adminComment, studentVisible: approveAndSendToStudent };
        api.suggestUniversities(+profileId, request)
            .then(resp => {
                toast('Suggesions saved successfuly', { type: 'success' })
                setRefreshKey(Math.random());
                history.push('/');
            })
            .finally(() => setLoading(false));
    }

    const handleCancel = () => {
        history.push('/');
    }

    if (!profile) {
        return <Spinner animation={'grow'} variant='primary' />
    }

    return <>
        <PageTitle breadcrumbs={[]}>University Suggestion</PageTitle>
        <ProfileHeader academicsData={academicProfile} profile={profile} showActions={false} exportPDF={() => { }}>
            <span key='course' className="badge badge-light-primary m-1">Course: {options?.course.name}</span>
            <span key='field' className="badge badge-light-primary m-1">Field: {options?.fieldOfStudy.name}</span>
            <span key='spc' className="badge badge-light-primary m-1">Specialization: {options?.specialization.name ?? "missing"}</span>
        </ProfileHeader>

        <div className="row">
            {academicProfile && <GraduationDetailsOverview academicsData={academicProfile} />}
        </div>
        <div className="row">
            {profile && <ExamScoresOverview data={{ profileId: profile.id, competitiveExam: profile.competitiveExam, languageExam: profile.languageExam }} />}
        </div>

        <div className="row">
            {academicProfile && <WorkDetailsOverview data={academicProfile.workExperiences || []} />}
        </div>

        <Button
            variant="outline-primary"
            className='btn btn-outline btn-sm mb-3 m-5'
            onClick={() => {
                setSuggestionPanelVisible(!suggestionPanelVisible);
            }}
        >
            Add new suggestion
        </Button>

        <div className={`${suggestionPanelVisible ? '' : 'd-none'}`}>
            {options?.suggestionOptions
                && <AddSuggestionPanel onAdded={(sugg) => handleSaveSuggestion(sugg)}
                    options={options.suggestionOptions.filter(x => suggestedIds.indexOf(x.id) < 0)} />
            }
        </div>
        {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
        )}
        <SuggestUniversitiesForm formik={formik} suggestionOptions={options?.suggestionOptions.filter(x => suggestedIds.indexOf(x.id) < 0) || []}  />

        <div className="col-12 my-2">
            <PreviousUniversityChanges suggestions={formik.values.archivedSuggestions} />
        </div>

        {formik.values.suggestions?.length !== 0 &&
            <>
                <div className="flex-column bg-white py-5">
                    <AdminComments comments={formik.values.comments as Array<any>} />
                    <div className='separator'></div>
                </div>
                <div className="d-flex mb-5 bg-white px-5">
                    <div className="m-5">
                        <label className='form-label fw-bold'>Approve and Notify Student:</label>
                        <Switch defaultChecked={sharedState.isSuperAdmin}
                            onChange={(ev) => setApproveAndSendToStudent(ev.target.checked)} /> {approveAndSendToStudent ? 'Yes' : 'No'}
                    </div>
                    {!approveAndSendToStudent && <div className="m-5">
                        <label className='form-label fw-bold' >Comments for Admin:</label>
                        <textarea className="form-control" cols={50} rows={1} onChange={(ev) => { setAdminComment(ev.target.value) }} />
                    </div>}
                </div>
                <div className="d-flex align-items-center mt-5 mb-5">
                    <Button className="btn btn-sm btn-primary col-xl-2 m-5" onClick={() => formik.handleSubmit()}>
                        {loading && <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>}
                        {!loading && 'Save'}</Button>
                    <Button className="btn btn-sm btn-secondary col-xl-2 m-5" onClick={handleCancel}>Cancel</Button>
                </div>
            </>
        }
    </>
}


const PreviousUniversityChanges: FC<{ suggestions: ISuggestedUniversityResponseDto[] }> = ({ suggestions }) => {
    const columns = [
        { title: 'University Name', field: 'universityName' },
        { title: 'Reason', field: 'reason' },
        { title: 'Suggested By', field: 'suggestedBy' },
        { title: 'Suggested At', field: 'createdAt' },
    ];

    return (
        <MaterialTable
            title="Previously Suggested Universities"
            columns={columns}
            data={suggestions}
            options={{
                search: false,
                paging: false,
                filtering: false,
            }}
        />
    );
}

export default withRouter(SuggestedUniversityPage);