import MaterialTable, {Action, Column, Query} from 'material-table'
import tableIcons from '../../../modules/common/components/MaterialTableIcons'
import {
  branchOptions,
  competitiveExamOptions,
  languageExamOptions,
  profileStatusOptions,
} from '../../../modules/common/DropdownOptions'
import {useHistory} from 'react-router-dom'
import {createRef, FC, useState} from 'react'
import {StudentProfile} from '../../student/models'
import {AxiosResponse} from 'axios'
import moment, {isDate} from 'moment'
import {IStudentFilter} from '../models/IStudentFilter'
import {StudentFilterType, StudentListFilters} from './StudentListFilter'
import {DATE_FORMAT_DD_MMM_YYYY} from '../../../modules/common/Helper'

function renderStatues(statues: string[]) {
  const colors = ['warning', 'danger', 'primary']
  if (!statues || statues.length === 0) {
    return <span className='badge badge-danger'>Migrated</span>
  } else {
    return statues.map((x, idx) => (
      <span key={x} className={`badge badge-light-${colors[idx] ?? 'primary'} mt-1`}>
        {x}
      </span>
    ))
  }
}

type StudentListProps = {
  getStudentsFromServer: (
    serachTerm: string | undefined,
    pageNumber: number,
    pageSize: number,
    filter: IStudentFilter,
    sortOrder?: string,
    sortField?: string
  ) => Promise<AxiosResponse>
  disabledFilters?: StudentFilterType[]
  actions?: Action<any>[] | any[]
  title?: string
  initialFilters?: IStudentFilter
  useDefaultActions?: boolean
  onLoad?: (count: number) => void
  allowSelection?: boolean
  extraColumns?: Array<Column<StudentProfile>>
}

const StudentList: FC<StudentListProps> = ({
  getStudentsFromServer,
  disabledFilters,
  actions,
  title,
  initialFilters,
  onLoad,
  useDefaultActions = true,
  allowSelection = false,
  extraColumns = [],
}) => {
  const defaultFilters: IStudentFilter = {
    branches: [],
    statuses: [],
    termIds: [],
    hasLanguageExamScores: false,
    hasCompetitiveExamScores: false,
    countries: [],
  }

  const defaultColumns = [
    {
      title: 'Name',
      field: 'firstName',
      render: (rowData: StudentProfile) =>
        [rowData.firstName, rowData.lastName].filter(Boolean).join(' ').toUpperCase(),
    },
    {title: 'Email', field: 'email'},
    {
      title: 'Branch',
      field: 'branch',
      render: (rowData: StudentProfile) =>
        branchOptions.find((x) => x.value === rowData.branchId)?.label,
    },
    {title: 'Term', field: 'termName'},
    {
      title: 'Approved On',
      render: (rowData: any) =>
        rowData?.profileState?.lorApprovedAt ? (
          moment(rowData?.profileState?.lorApprovedAt).format('DD-MM-YYYY')
        ) : (
          <span style={{marginLeft: 20}}>-</span>
        ),
    },
    {
      title: 'Status',
      sorting: false,
      field: 'statuses',
      render: (rowData: StudentProfile) =>
        rowData.statuses ? renderStatues(rowData.statuses) : null,
    },
    ...extraColumns,
  ]

  const history = useHistory()
  const [isLoading, setLoading] = useState(false)
  const [filter, setFilter] = useState<IStudentFilter>({...defaultFilters, ...initialFilters})
  const [pageSize, setPageSize] = useState<number>(10)

  const tableRef = createRef()

  const defaultActions = useDefaultActions
    ? [
        {
          icon: tableIcons.OpenInNew as any,
          tooltip: 'View Student Profile',
          disabled: false,
          onClick: (event: any, rowData: any) => {
            const url = `/student/${rowData.id}`
            history.push(url)
          },
        },
      ]
    : []

  const allActions = [...defaultActions, ...(actions || [])]

  const onFilterChanged = (field: StudentFilterType, value: any) => {
    switch (field) {
      case 'termIds':
        setFilter({...filter, termIds: value})
        break
      case 'statuses':
        setFilter({...filter, statuses: value})
        break
      case 'branches':
        setFilter({...filter, branches: value})
        break
      case 'examDate':
        setFilter({...filter, examDate: isDate(value) ? moment(value).format() : undefined})
        break
      case 'hasLanguageExamScores':
        setFilter({...filter, hasLanguageExamScores: value})
        break
      case 'hasCompetitiveExamScores':
        setFilter({...filter, hasCompetitiveExamScores: value})
        break
      case 'studentType':
        setFilter({...filter, studentType: value})
        break
      case 'countries':
        setFilter({...filter, countries: value})
        break
      case 'documentType':
        setFilter({...filter, documentTypeFilter: value})
        break
      default:
        break
    }
  }

  const onSearch = () => {
    if (tableRef && tableRef.current) {
      ;(tableRef.current as any).onQueryChange()
    }
  }

  const transformApiResponse = (response: AxiosResponse<any>, resolve: any) => {
    let {data} = response.data
    onLoad && onLoad(response.data.totalCount)
    data = data.map((prof: any) => {
      const statuses = profileStatusOptions
        .filter((x) => prof.profileStatuses?.includes(x.value))
        .map((x) => x.label)
      return {...prof, statuses}
    })
    return resolve({
      data,
      page: response.data.page - 1,
      totalCount: response.data.totalCount,
    })
  }

  return (
    <div className='row'>
      <div className='col-xl-12 mb-10 bg-white pt-5'>
        <StudentListFilters
          initialFilter={filter}
          onFilterChanged={onFilterChanged}
          onSearch={onSearch}
          disabledFilters={disabledFilters}
        />
      </div>
      <div className='col-xl-12'>
        <MaterialTable
          title={title || 'Student Profiles'}
          icons={tableIcons}
          tableRef={tableRef}
          columns={defaultColumns}
          actions={allActions}
          data={(query: Query<StudentProfile>) => {
            const {page, pageSize, search, orderBy, orderDirection} = query
            setPageSize(pageSize)
            const field = orderBy ? orderBy.field : 'firstName'
            return new Promise((resolve, reject) => {
              setLoading(true)
              getStudentsFromServer(
                search?.trim(),
                page + 1,
                pageSize,
                filter,
                orderDirection,
                field
              )
                .then((resp) => {
                  console.log('getStudentsFromServer', resp)
                  transformApiResponse(resp, resolve)
                })
                .catch((error) => reject(error))
                .finally(() => setLoading(false))
            })
          }}
          isLoading={isLoading}
          options={{
            debounceInterval: 700,
            filtering: false,
            selection: allowSelection,
            pageSize: pageSize,
            pageSizeOptions: [5, 10, 20, 50, 100],
          }}
        />
      </div>
    </div>
  )
}

export default StudentList

export const StudentListColumnMap = {
  Exam_1: {
    title: 'Exam - 1',
    render: (data: any) => {
      return (
        <>
          <span
            className={`badge m-1 badge-${
              data.competitiveExam?.total ? 'light-info' : 'light-danger'
            }`}
          >
            {competitiveExamOptions.find((x) => x.value === data.competitiveExam?.examType)?.label}{' '}
            {data.competitiveExam?.total ||
              moment(data.competitiveExam?.examDate).format('DD-MMM') ||
              'NA'}{' '}
          </span>
        </>
      )
    },
  },
  Exam_2: {
    title: 'Exam - 2',
    render: (data: any) => {
      return (
        <>
          <span
            className={`badge m-1 badge-${
              data.languageExam?.total ? 'light-info' : 'light-danger'
            }`}
          >
            {languageExamOptions.find((x) => x.value === data.languageExam?.examType)?.label}{' '}
            {data.languageExam?.total ||
              moment(data.languageExam?.examDate).format('DD-MMM') ||
              'NA'}{' '}
          </span>
        </>
      )
    },
  },
  Status: {
    title: 'Status',
    field: 'statuses',
    render: (rowData: StudentProfile) =>
      rowData.statuses ? renderStatues(rowData.statuses) : null,
  },
  CreatedDate: {
    title: 'Created Date',
    render: (data: StudentProfile) => moment(data.createdOn).format(DATE_FORMAT_DD_MMM_YYYY),
  },
  CreatedBy: {title: 'Default BLL', field: 'createdBy'},
  DocumentType: {
    title: 'DocumentType',
    field: 'lorUri',
    render: (rowData: StudentProfile) => (rowData.lorUri ? 'LOR' : 'SOP'),
  },
  QuestionnaireApprovedDate: {
    title: 'Questionnaire Approved',
    width: '100px',
    field: 'lorApprovedDate',
    render: (rowData: StudentProfile) =>
      (rowData.lorApprovedDate
        ? moment(rowData.lorApprovedDate)
        : moment(rowData.sopApprovedDate)
      ).format('DD-MMM-YYYY'),
  },
  SuggestedBy: {title: 'Suggested By', field: 'suggestedBy', width: '100px'},
}
