/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import Notice from '../../../modules/common/components/Notice'
import { graduationTypeOptions } from '../../../modules/common/DropdownOptions'
import { GraduationType, IAcademicProfile } from '../../student/models'
import { BachelorGradesView, DiplomaGradesView, DiplomaPlusBachelorGradesView, MastersGradesView, JuniorCollegeGradesView } from './internal'

type Props = {
    academicsData: IAcademicProfile
}

export const GraduationDetailsOverview: FC<Props> = ({ academicsData }) => {
    const NotFoundNotice: FC = () => (<Notice title='No graduation details found!' message='Graduation Details not found for student account.' />);
    const renderSwitch = (gradType?: GraduationType) => {
        switch (gradType) {
            case GraduationType.Bachelors:
                return academicsData.graduationDetail.bachelorDegreeDetail
                    && <BachelorGradesView data={academicsData.graduationDetail.bachelorDegreeDetail} />;
            case GraduationType.Diploma:
                return academicsData.graduationDetail.diplomaDegreeDetail
                    && <DiplomaGradesView data={academicsData.graduationDetail.diplomaDegreeDetail} />;
            case GraduationType.DiplomaPlusBachelorDegree:
                return academicsData.graduationDetail.diplomaPlusBachelorDegreeDetail
                    && <DiplomaPlusBachelorGradesView data={academicsData.graduationDetail.diplomaPlusBachelorDegreeDetail} />;
            case GraduationType.Masters:
                return academicsData.graduationDetail.masterDegreeDetail
                    && <MastersGradesView data={academicsData.graduationDetail.masterDegreeDetail} />;
            case GraduationType.JuniorCollege:
                return academicsData.graduationDetail.juniorCollegeDetail
                    && <JuniorCollegeGradesView data={academicsData.graduationDetail.juniorCollegeDetail} />;
            default:
                return null;
        }
    }

    return (
        <>
            <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Graduation Details</h3>
                    </div>
                </div>

                <div className='card-body'>
                    {academicsData?.graduationDetail ?
                        <>
                            <div className='row mb-7'>
                                <div className="col-lg-10">
                                    <div className="row">
                                        <label className='col-lg-4 fw-bold text-muted'>Graduation Type</label>
                                        <div className='col-lg-4'>
                                            <span className='fw-bolder fs-6 text-dark'>
                                                {graduationTypeOptions.find(x => x.value === academicsData.graduationDetail.graduationType)?.label}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-7'>
                                <div className='fw-bolder fs-6 text-dark'>
                                    {renderSwitch(academicsData.graduationDetail.graduationType)}
                                </div>
                            </div>

                            <div className='row mb-7'>
                                <div className='fw-bolder fs-6 text-dark'>
                                    {academicsData.otherRemarks}
                                </div>
                            </div>
                        </>
                        :
                        <NotFoundNotice />
                    }
                </div>
            </div>
        </>
    )
}
