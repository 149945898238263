import moment, {isDate} from 'moment'
import {FC, useState} from 'react'
import {PageTitle} from '../../../theme/layout/core'
import {GenerateReportRequestDto, IReportResponseDto} from './GenerateReportRequestDto'
import {ReportFilters, StudentFilterType} from './ReportFilters'
import {callGenerateReportApi} from './ReportService'
import MaterialTable, {Column} from 'material-table'
import tableIcons from '../../modules/common/components/MaterialTableIcons'
import {ExportToCsv} from 'export-to-csv'

const ReportsPage: FC = () => {
  const [filter, setFilter] = useState<GenerateReportRequestDto>({})
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<IReportResponseDto[]>([])

  const columns: Column<IReportResponseDto>[] = [
    {title: 'Full Name', field: 'fullName', headerStyle: {width: '200px'}},
    {title: 'Email', field: 'email', headerStyle: {width: '200px'}},
    {title: 'Gender', field: 'gender', headerStyle: {width: '200px'}},
    {title: 'Interested Countries', field: 'interestedCountries', headerStyle: {width: '200px'}},
    {title: 'Parent Phone Number', field: 'parentPhoneNumber', headerStyle: {width: '200px'}},
    {title: 'Student Phone Number', field: 'studentPhoneNumber', headerStyle: {width: '200px'}},
    {title: 'Passport Number', field: 'passportNumber', headerStyle: {width: '200px'}},
    {title: 'Requires Essay', field: 'requiresEssay', headerStyle: {width: '200px'}},
    {title: 'Requires Lor', field: 'requiresLor', headerStyle: {width: '200px'}},
    {title: 'Requires Sop', field: 'requiresSop', headerStyle: {width: '200px'}},
    {title: 'Term Name', field: 'termName', headerStyle: {width: '200px'}},
    {title: 'UserId', field: 'userId', headerStyle: {width: '200px'}},
    {title: 'Total Fees Charged', field: 'totalFeesCharged', headerStyle: {width: '200px'}},
    {title: 'Fees Paid', field: 'feesPaid', headerStyle: {width: '200px'}},
    {title: 'Expected Payment Date', field: 'expectedPaymentDate', headerStyle: {width: '200px'}},
    {title: 'Date Of Birth', field: 'dateOfBirth', headerStyle: {width: '200px'}},
    {title: 'Account Created On', field: 'accountCreatedOn', headerStyle: {width: '200px'}},
    {title: 'Account Created By', field: 'accountCreatedBy', headerStyle: {width: '200px'}},
    {title: 'Branch', field: 'branch', headerStyle: {width: '200px'}},
    {title: 'Other Remarks', field: 'otherRemarks', headerStyle: {width: '200px'}},
    {title: 'Language Exam', field: 'languageExam', headerStyle: {width: '200px'}},
    {title: 'Is Language Exam Given', field: 'isLanguageExamGiven', headerStyle: {width: '200px'}},
    {
      title: 'Total Score (Language Exam)',
      field: 'totalScoreLanguageExam',
      headerStyle: {width: '200px'},
    },
    {title: 'Competitive Exam', field: 'competitiveExam', headerStyle: {width: '200px'}},
    {
      title: 'Is Competitive Exam Given',
      field: 'isCompetitiveExamGiven',
      headerStyle: {width: '200px'},
    },
    {
      title: 'Total Score (Competitive Exam)',
      field: 'totalScoreCompetitiveExam',
      headerStyle: {width: '200px'},
    },

    {title: 'Current Education', field: 'currentEducation', headerStyle: {width: '200px'}},
    {title: 'Future Desired Degree', field: 'futureDesiredDegree', headerStyle: {width: '200px'}},
    {title: 'Desired Course', field: 'desiredCourse', headerStyle: {width: '200px'}},
    {title: 'Desired Field of Study', field: 'desiredFieldOfStudy', headerStyle: {width: '200px'}},
    {
      title: 'Desired Specialization',
      field: 'desiredSpecialization',
      headerStyle: {width: '200px'},
    },
    {
      title: 'University Suggestion State',
      field: 'universitySuggestionState',
      headerStyle: {width: '200px'},
    },
    {
      title: 'Suggested Universities',
      field: 'suggestedUniversities',
      headerStyle: {width: '200px'},
      render: (d) => {
        return (
          <ol>
            {d.suggestedUniversities.split('\n').map((li) => (
              <li>{li}</li>
            ))}
          </ol>
        )
      },
    },
    {title: 'Finalized University', field: 'finalizedUniversity', headerStyle: {width: '200px'}},
    {title: 'CWID Number', field: 'cwidNumber', headerStyle: {width: '200px'}},
    {title: 'Partner Name', field: 'finalizedUniversityPartnerName', headerStyle: {width: '200px'}},
    {title: 'Is Inactive', field: 'isInactive', headerStyle: {width: '200px'}},

    {title: 'Grading Systen', field: 'gradingSystem', headerStyle: {width: '200px'}},
    {title: 'College Details', field: 'collegeDetails', headerStyle: {width: '150px'}},
    {title: 'First Year Score', field: 'firstYearScore', headerStyle: {width: '150px'}},
    {title: 'Second Year Score', field: 'secondYearScore', headerStyle: {width: '150px'}},
    {title: 'Third Year Score', field: 'thirdYearScore', headerStyle: {width: '150px'}},
    {title: 'Fourth Year Score', field: 'fourthYearScore', headerStyle: {width: '150px'}},
    {title: 'Fifth Year Score', field: 'fifthYearScore', headerStyle: {width: '150px'}},
    {title: 'Sixth Year Score', field: 'sixthYearScore', headerStyle: {width: '150px'}},
    {title: 'Seventh Year Score', field: 'seventhYearScore', headerStyle: {width: '150px'}},
    {title: 'Eighth Year Score', field: 'eighthYearScore', headerStyle: {width: '150px'}},
    {title: 'Profile Statuses', field: 'profileStatuses', headerStyle: {width: '150px'}},
  ]

  const csvOptions = {
    // fieldSeparator: ',',
    // quoteStrings: '"',
    // decimalSeparator: '.',
    // showLabels: true,
    // useBom: true,
    useKeysAsHeaders: true,
    // headers: columns.map((c) => c.title as string),
  }
  const csvExporter = new ExportToCsv(csvOptions)

  const handleExportData = () => {
    csvExporter.generateCsv(data)
  }

  const onFilterChanged = (field: StudentFilterType, value: any) => {
    switch (field) {
      case 'termIds':
        setFilter({...filter, termIds: value})
        break
      case 'statuses':
        setFilter({...filter, statuses: value})
        break
      case 'branches':
        setFilter({...filter, branches: value})
        break
      case 'examDate':
        setFilter({...filter, examDate: isDate(value) ? moment(value).format() : undefined})
        break
      case 'hasLanguageExamScores':
        setFilter({...filter, hasLanguageExamScores: value})
        break
      case 'hasCompetitiveExamScores':
        setFilter({...filter, hasCompetitiveExamScores: value})
        break
      default:
        break
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>Generate Report</PageTitle>
      <ReportFilters
        isLoading={isLoading}
        onFilterChanged={onFilterChanged}
        onSearch={() => {
          setLoading(true)
          callGenerateReportApi(filter)
            .then(({data}) => {
              setData(data)
            })
            .finally(() => {
              setLoading(false)
            })
        }}
      />

      {data?.length > 0 && !isLoading && (
        <MaterialTable
          title={'Report'}
          icons={tableIcons}
          columns={columns}
          data={data}
          actions={[
            {
              isFreeAction: true,
              icon: tableIcons.Export as any,
              onClick: () => {
                handleExportData()
              },
            },
          ]}
          options={{
            debounceInterval: 700,
            sorting: true,
            filtering: false,
            pageSize: 10,
            actionsColumnIndex: -1,
          }}
        />
      )}
    </>
  )
}

export default ReportsPage
