import { FC, useEffect, useState } from "react"
import { VerbalScore, QuantitativeScore, AWAScore, ReadonlyTotalScore } from "./Score"

type Props = {
    formik: any,
    fieldPath: string,
}

export const GREScore: FC<Props> = ({ formik, fieldPath }) => {
    const props = { formik, fieldPath: `${fieldPath}.greScore` }
    const [total, setTotal] = useState(0);

    useEffect(() => {
        if (!formik.values.competitiveExam?.greScore) return;
        const { quantitative, verbal } = formik.values.competitiveExam?.greScore;
        if (quantitative && verbal) {
            setTotal(quantitative + verbal);
        }
    }, [formik, setTotal]);

    return <>
        <ReadonlyTotalScore total={total} />
        <div className="row fv-row mb-10">
            <div className="col-xl-4">
                <VerbalScore {...props} />
            </div>
            <div className="col-xl-4">
                <QuantitativeScore {...props} />
            </div>
            <div className="col-xl-4">
                <AWAScore {...props} />
                <span className="badge badge-light-success m-1">AWA Field Not Compulsory</span>
            </div>
        </div>
    </>
}
