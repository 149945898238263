import {FC, useState, useEffect} from 'react'
import {ButtonGroup, ToggleButton} from 'react-bootstrap-v5'
import {toast} from 'react-toastify'
import {StudentTypeEnum} from '../../../modules/common/enums/StudentTypeEnum'
import {updateStudentType} from '../../student/services/StudentProfileService'

export const StudentTypeEditor: FC<{profileId: number; studentType?: StudentTypeEnum}> = ({
  profileId,
  studentType,
}) => {
  const [currentType, setType] = useState(studentType)
  const [loading, setLoading] = useState(false)

  const doWork = async (val: any) => {
    setLoading(true)
    updateStudentType(profileId, val).then(() => {
      toast('Saved', {type: 'success'})
      setLoading(false)
    })
  }
  useEffect(() => {
    if (currentType === null) {
      toast.warning('Indicate Student Type as Hyper If They have Opted for A PREMIUM Package!')
    }
  }, [currentType])

  return (
    <div>
      <div className='mx-5'>
        <label className='form-label mx-2 fw-bold'>Type</label>
        <ButtonGroup className='mb-2' size='sm'>
          <ToggleButton
            key={'star'}
            disabled={loading}
            type='radio'
            variant='outline-info'
            className='btn-outline'
            checked={currentType === StudentTypeEnum.Star}
            value={currentType}
            onClick={(e) => {
              if (currentType === StudentTypeEnum.Star) {
                setType(undefined)
              } else {
                setType(StudentTypeEnum.Star)
              }
              doWork(StudentTypeEnum.Star)
            }}
          >
            Star
          </ToggleButton>
          <ToggleButton
            key={'hyper'}
            type='radio'
            disabled={loading}
            variant='outline-info'
            className='btn-outline'
            checked={currentType === StudentTypeEnum.Hyper}
            value={currentType}
            onClick={(e) => {
              if (currentType === StudentTypeEnum.Hyper) {
                setType(undefined)
              } else {
                setType(StudentTypeEnum.Hyper)
              }
              doWork(StudentTypeEnum.Hyper)
            }}
          >
            Hyper
          </ToggleButton>
          {loading && (
            <span className='indicator-progress m-2' style={{display: 'block'}}>
              Saving...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </ButtonGroup>
      </div>
    </div>
  )
}
